import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import {
  useTheme,
  View,
  Image,
  useAuthenticator,
  Divider,
  Heading,
  Button
} from '@aws-amplify/ui-react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import './scss/style.scss'
import logoPK from './assets/images/logo.jfif'
import logo from './assets/images/brocal-logo-normal.png'
import './styles/App.css'
import { I18n } from 'aws-amplify/utils';
import awsExports from './aws-exports';
import config  from './amplifyconfiguration.json';
import AWS from 'aws-sdk';
import { getCurrentUser } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import { inputAdornmentClasses } from '@mui/material';
import { GetInterpolatedAssetPropertyValuesCommand } from '@aws-sdk/client-iotsitewise';
import { isPasteShortcut } from '@mui/x-data-grid/internals';

Amplify.configure(config);

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

let logStreamName = ''
let session = true
let logout = true

const theme = {

  tokens: {
    components: {
      divider: {
        borderStyle: { value: 'dotted' },
        borderColor: { value: '{colors.blue.80}' },
        borderWidth: { value: '{borderWidths.small}' },

        label: {
          color: { value: '{colors.blue}' },
          backgroundColor: { value: '{colors.blue.80}' },
        },

      },
    },
  },
};

const formFields = {
  signIn: {
    username: {
      label: 'Usuario:',
      placeholder: 'Ingresa tu Usuario',
    },
    password: {
      label: 'Contraseña:',
      placeholder: 'Ingresa tu Contraseña',
    },


  },


}

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <Heading paddingTop={'20%'}>
      </Heading>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <>
          <div className='text-center' style={{marginTop:'2px'}}>
            <Image
              alt="Amplify logo"
              src={logo}
              height={250}
            />
            <div className='row line-center'>
          
                <Divider label="" className='' />
           
            </div>

          </div>
        </>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <div className='text-center' style={{marginTop:'0px'}}>

          
        <View textAlign="center" style={{marginBottom:'10px'}}>
            <Button  onClick={toForgotPassword} size="small">
            ¿Olvidaste tu Contraseña?
            </Button>
          </View>
         <Divider label="By" className='' style={{marginBottom:'10px'}}/>
        <Image
          alt="Amplify logo"
          src={logoPK}
          height={60}
        />



      </div>

      );
    }
  },
}

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const dict = {
  es: {
    'Sign in': 'Iniciar Sesión',
    'Forgot your password?': 'Recuperar Contraseña',
    'Reset Password': 'Restablecer la contraseña',
    'Enter your username': 'Ingrese su usuario',
    'Reset Password': 'Restablecer la contraseña',
    'Send code': 'Enviar Codigo',
    'Back to Sign In': 'Volver para iniciar sesión',
    'Code': 'Codigo',
    'New Password': 'Nueva contraseña',
    'Confirm Password': 'Confirmar contraseña',
    'Submit': 'Enviar',
    'Resend Code': 'Reenviar Codigo',
    'There is already a signed in user.' : 'Ya hay un usuario Logueado',
    'Change Password' : 'Actualizar Contraseña',
    'Password' : 'Contraseña',
    'Password must have at least 8 characters' : 'La contraseña debe tener al menos 8 caracteres.',
    'Enter your Password' : 'Ingrese su Contraseña',
    'Please confirm your Password' : 'Confirme su Contraseña',
    'Change Password' : 'Actualizar Contraseña',
    'Account recovery requires verified contact information': 'La recuperación de la cuenta requiere información de contacto verificada',
    'Verify':'Verificar',
    'Skip':'Saltar',
    'Email':'Correo Electronico',
    'Enter your Email': 'Ingrese su correo electronico',
    'Code' : 'Codigo',
    'Signing in': 'Iniciando...',
  }
};

I18n.putVocabularies(dict)

const logSingInAcces = async (username) =>{
  try {
      const authSession = await fetchAuthSession();
      const credentials = authSession.credentials;

      AWS.config.update({
        region: 'us-east-1',
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken
      });

      const ip = await fetchIp()
      const userAgent = navigator.userAgent;
      const operatingSystem = getOS(userAgent);

      const eventCloudwatch = {
        dateAccessed: convertTimestampToDate(new Date().getTime()),
        user: username,
        event:'input',
        ipAddress: ip,
        SO:operatingSystem
      }
      
      createAndSendLog(eventCloudwatch)
 
  } catch (error) {
    logout = true
    console.error(error)
  }
}

const logSingOut = async (username) =>{
 
  try {
    logout = false
    const authSession = await fetchAuthSession();
    const credentials = authSession.credentials;

    AWS.config.update({
      region: 'us-east-1',
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken
    });

    const ip = await fetchIp()
    const userAgent = navigator.userAgent;
    const operatingSystem = getOS(userAgent);

    const eventCloudwatch = {
      dateAccessed: convertTimestampToDate(new Date().getTime()),
      user: username,
      event:'ouput',
      ipAddress: ip,
      SO:operatingSystem
    }
    createAndSendLog(eventCloudwatch)
  } catch (error) {
    console.error(error)
  }
}

const fetchIp = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching the IP address:', error);
  }
};

const getOS = (userAgent) => {
  let os = 'Unknown OS';
  if (userAgent.indexOf('Win') !== -1) os = 'Windows';
  if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
  if (userAgent.indexOf('X11') !== -1) os = 'UNIX';
  if (userAgent.indexOf('Linux') !== -1) os = 'Linux';
  if (/Android/.test(userAgent)) os = 'Android';
  if (/iPhone|iPad|iPod/.test(userAgent)) os = 'iOS';
  return os;
};

const getLastLogStream = async () => {
  const cloudwatchlogs = new AWS.CloudWatchLogs();
  const params = {
    logGroupName: '/plataforma-molino20x30/monitoreo-user',
    orderBy: 'LogStreamName',
    descending: true,
    limit: 50
  };

  return new Promise((resolve, reject) => {
    cloudwatchlogs.describeLogStreams(params, (err, data) => {
      if (err) {
        console.log('Error al obtener los log streams:', err);
        reject(err);
      } else {
        const logStreams = data.logStreams;
        if (logStreams.length > 0) {
          // Ordenar los log streams por nombre y obtener el último
          const lastLogStream = logStreams
            .map(ls => ls.logStreamName)
            .sort()
            .reverse()[0];
          resolve(lastLogStream);
        } else {
          resolve(null);
        }
      }
    });
  });
};
//logss
const createAndSendLog = async (eventCloudwatch) => {

  logStreamName = await getLastLogStream();

  if (!logStreamName) return;

  const cloudwatchlogs = new AWS.CloudWatchLogs();
    
    const params = {
    logGroupName: '/plataforma-molino20x30/monitoreo-user',
    logStreamName: logStreamName,
    logEvents: [
      {
      message: JSON.stringify(eventCloudwatch),
      timestamp: new Date().getTime()
      }
    ]
  };
  
  cloudwatchlogs.putLogEvents(params, function(err, data) {
    if (err) {
      console.log('Error al enviar log de auth a CloudWatch:', err);
    } else {
      console.log('Log auth enviado exitosamente a CloudWatch:', data);
    }
  });
};

const convertTimestampToDate = (timestamp) => {
  const timestampNumber = Number(timestamp);

  if (isNaN(timestampNumber)) {
    return "Timestamp inválido";
  }

  const fecha = new Date(timestampNumber);

  const padToTwoDigits = (num) => num.toString().padStart(2, '0');

  const dia = padToTwoDigits(fecha.getDate());
  const mes = padToTwoDigits(fecha.getMonth() + 1); 
  const anio = fecha.getFullYear();
  const horas = padToTwoDigits(fecha.getHours());
  const minutos = padToTwoDigits(fecha.getMinutes());
  const segundos = padToTwoDigits(fecha.getSeconds());

  const fechaFormateada = `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;

  return fechaFormateada;
}


function App() {
  return (
    <div className="">
      <Authenticator hideSignUp theme={theme} formFields={formFields} components={components}>
     
        {({ signOut, user }) => {
        
          if (session && logout){
            logSingInAcces(user.username); 
            logout = false
          }else{
            logout = true
            console.log("value",logout)

          }
          //console.log("2.",session)

          const handleSignOut = async () => {
            await logSingOut(user.username);  
            signOut();
          };
          return (
            <HashRouter>
              <React.Suspense fallback={loading()}>
                <Routes>
                  <Route path="*" element={<React.Suspense fallback={loading()}><DefaultLayout signOut={handleSignOut} user={user} /></React.Suspense>} />
                </Routes>
              </React.Suspense>
            </HashRouter>
          );
        }}
      </Authenticator>
    </div>
  );
}


export default App;